import { nextTick as vueNextTick, watch } from 'vue'
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { useLoginData } from '@/store/loginData'
import vueAPI from '@/modules/vueAPI'
import mainPage from '@/views/mainPage.vue'

import loginPage from '@/views/loginPage.vue'
import feedPage from '@/views/feedPage.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'mainPage',
        component: mainPage,
        children: [
            {
                path: '',
                name: 'feed',
                component: feedPage,
                meta: {
                    requiresAuth: true,
                    noFooter: true
                }
            },
            {
                path: 'messages',
                name: 'messages',
                meta: {
                    requiresAuth: true,
                    noFooter: true
                },
                component: () => import(/* webpackChunkName: "messagesPage" */ '@/views/messages/messagesView.vue'),
                children: [
                    {
                        path: '',
                        name: 'messagesListPage',
                        component: () => import(/* webpackChunkName: "messagesPage" */ '@/views/messages/messagesPage.vue')
                    },
                    {
                        path: 'new',
                        name: 'newMessagePage',
                        component: () => import(/* webpackChunkName: "messagesPage" */ '@/views/messages/newMessagePage.vue')
                    },
                    {
                        path: 'u:user_id(\\d+)',
                        name: 'coversationPage',
                        component: () => import(/* webpackChunkName: "messagesPage" */ '@/views/messages/conversationPage.vue'),
                    }
                ]
            },
            {
                path: 'hunters',
                name: 'hunters',
                meta: {
                    requiresAuth: true,
                    noFooter: true
                },
                component: () => import(/* webpackChunkName: "huntersPage" */ '@/views/huntersPage.vue'),
            },
            {
                path: 'p/:post_id(\\S+)',
                name: 'post',
                component: () => import(/* webpackChunkName: "postPage" */ '@/views/postPage.vue'),
            },
            {
                path: 'profile',
                name: 'user_profile',
                meta: {
                    requiresAuth: true,
                    noFooter: true
                },
                children: [
                    {
                        path: '',
                        name: '404_profile',
                        redirect: { name: '404' }
                    },
                    {
                        path: 'edit',
                        name: 'user_profile_edit',
                        component: () => import(/* webpackChunkName: "userProfileEdit" */ '@/components/userProfile/editProfile.vue'),
                    },
                    {
                        path: 'settings',
                        name: 'user_profile_settings',
                        children: [
                            {
                                path: '',
                                name: 'user_profile_settings_general',
                                component: () => import(/* webpackChunkName: "userProfileSettings" */ '@/components/userProfile/settings/profileSettings.vue')
                            },
                            {
                                path: 'account',
                                name: 'user_profile_settings_account',
                                component: () => import(/* webpackChunkName: "userProfileSettingsAccount" */ '@/components/userProfile/settings/accountSettings.vue')
                            },
                            {
                                path: 'payments',
                                name: 'user_profile_settings_payments',
                                component: () => import(/* webpackChunkName: "userProfileSettingsPayments" */ '@/components/userProfile/settings/paymentsSettings.vue')
                            },
                            {
                                path: 'privacy',
                                name: 'user_profile_settings_privacy',
                                component: () => import(/* webpackChunkName: "userProfileSettingsPrivacy" */ '@/components/userProfile/settings/privacySettings.vue')
                            },
                            {
                                path: 'security',
                                name: 'user_profile_settings_security',
                                component: () => import(/* webpackChunkName: "userProfileSettingsSecurity" */ '@/components/userProfile/settings/securitySettings.vue')
                            },
                            {
                                path: 'notifications',
                                name: 'user_profile_settings_notifications',
                                component: () => import(/* webpackChunkName: "userProfileSettingsNotifications" */ '@/components/userProfile/settings/notificationsSettings.vue')
                            },
                            {
                                path: 'paid-services',
                                name: 'user_profile_settings_paid_services',
                                component: () => import(/* webpackChunkName: "userProfileSettingsPaidServices" */ '@/components/userProfile/settings/paidServices.vue')
                            },
                            {
                                path: 'ads',
                                name: 'user_profile_settings_ads',
                                component: () => import(/* webpackChunkName: "userProfileSettingsAds" */ '@/components/userProfile/settings/adsSettings.vue')
                            },
                            {
                                path: 'chats',
                                name: 'user_profile_settings_chats',
                                component: () => import(/* webpackChunkName: "userProfileSettingsChats" */ '@/components/userProfile/settings/chatsSettings.vue')
                            },
                            {
                                path: 'posts',
                                name: 'user_profile_settings_posts',
                                component: () => import(/* webpackChunkName: "userProfileSettingsPosts" */ '@/components/userProfile/settings/postsSettings.vue')
                            },
                            {
                                path: 'enjoyers',
                                name: 'user_profile_settings_enjoyers',
                                component: () => import(/* webpackChunkName: "userProfileSettingsEnjoyers" */ '@/components/userProfile/settings/enjoyersSettings.vue')
                            }
                        ]
                    }
                ]
            },
            {
                path: ':user_display_url',
                name: 'user_profile_view',
                component: () => import(/* webpackChunkName: "userProfile" */ '@/views/userProfile.vue'),
                children: [
                    {
                        path: '',
                        name: 'user_profile_posts',
                        component: () => import(/* webpackChunkName: "userProfile" */ '@/components/userProfile/userPosts.vue'),
                    },
                    {
                        path: 'feed',
                        name: 'user_profile_feed',
                        component: () => import(/* webpackChunkName: "userProfile" */ '@/components/userProfile/userFeed.vue')
                    },
                    {
                        path: 'videos',
                        name: 'user_profile_videos',
                        component: () => import(/* webpackChunkName: "userProfile" */ '@/components/userProfile/userVideos.vue')
                    },
                    {
                        path: 'fan-posts',
                        name: 'user_profile_fan_posts',
                        component: () => import(/* webpackChunkName: "userProfile" */ '@/components/userProfile/userFansPosts.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/timer',
        name: 'timerPage',
        component: () => import(/* webpackChunkName: "timerPage" */ '@/views/timerPage.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: loginPage,
        // redirect: { path: '/timer' },
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import(/* webpackChunkName: "calendar" */ '@/views/calendar.vue')
    },
    {
        path: '/canvas',
        name: 'canvas',
        component: () => import(/* webpackChunkName: "canvas" */ '@/views/canvas.vue')
    },
    {
        path: '/canvastest',
        name: 'canvas-test',
        component: () => import(/* webpackChunkName: "canvasTest" */ '@/views/canvasTest.vue')
    },
    {
        path: '/pwd-check',
        name: 'pwd-check',
        component: () => import(/* webpackChunkName: "password-check" */ '@/views/passwordCheck.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "test" */ '@/views/test.vue')
    },
    {
        path: '/map',
        name: 'map',
        component: () => import(/* webpackChunkName: "map" */ '@/views/map.vue')
    },
    {
        path: '/notification',
        name: 'notification',
        component: () => import(/* webpackChunkName: "notificationPage" */ '@/views/notificationPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/photoTest',
        name: 'photoTest',
        component: () => import(/* webpackChunkName: "photoTest" */ '@/views/photoTest.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/404',
        name:'404',
        component: () => import(/* webpackChunkName: "404Page" */ '@/views/404.vue')
    }
    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else if (from.path !== to.path) {
            return {
                top: 0,
                behavior: 'smooth'
            }
        }
    }
})

router.beforeEach(async (to) => {
    const loginData = useLoginData()
    if (to.meta.requiresAuth || to.meta.requiresAdmin || to.meta.requiresGuest) {
        if (loginData.getCheckingForLogin) {
            await new Promise<void>((resolve) => {
                let unwatchThis = watch(() => loginData.getCheckingForLogin, () => {
                    unwatchThis()
                    resolve()
                })
            })
        }

        if (to.meta.requiresAuth) {
            if (!loginData.getLoginState) return{ path: '/login' }
        } else if (to.meta.requiresAdmin) {
            if (!loginData.getLoginState) return { path: '/login' }
            if (!loginData.getAdminState) return { path: '/login' }
        } else if (to.meta.requiresGuest) {
            if (loginData.getLoginState) return { path: '/' }
        }
    }

    return true
})

const DEFAULT_TITLE = 'KissHunter'
router.afterEach(async (to) => {
    await vueNextTick()
    if (document.title === (to.meta.title || DEFAULT_TITLE)) return
    document.title = (to.meta.title || DEFAULT_TITLE) as string
})

export default router
