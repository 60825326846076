<template>
    <main class="login_section_wrapper">
        <div class="wrapper">
            <div class="mobile_image"></div>
            <div class="login_regist_form_wrapper">
                <div class="header_logo noselect" @click="$router.push('/')">
                    <kissHunter-Icon-SVG></kissHunter-Icon-SVG>
                </div>
                <div class="form_wrapper">
                    <div class="buttons_loging_register noselect">
                        <span class="registr_button" v-if="isRegistrating" @click="isRegistrating = false">{{ $t('login') }}</span>
                        <span class="registr_button" v-else @click="isRegistrating = true">{{ $t('sign-up') }}</span>
                    </div>
                    <form class="logReg_form" @submit.prevent="submitForm()">
                        <div class="register_form" v-if="isRegistrating">
                            <input type="text" required :placeholder="$t('your_name')" v-model="loginData.registerUserData.username">
                            <input type="email" required :placeholder="$t('your_email')" v-model="loginData.registerUserData.email">
                            <input type="password" required :placeholder="$t('password')" v-model="loginData.registerUserData.password">
                            <input type="password" required :placeholder="$t('repeat_password')" v-model="loginData.registerUserData.password2">
                            <button class="action_button" type="submit">
                                <span>{{ $t('register') }}</span>
                            </button>
                            <div class="space_separator">
                                <span class="line"></span>
                                <span>{{ $t('or') }}</span>
                                <span class="line"></span>
                            </div>
                            <div class="social_media_logReg_buttons_wrapper">
                                <div class="special_login_button">
                                    <div class="media_icon g"></div>
                                    <span>{{ $t('register_with') }} Google</span>
                                </div>
                                <div class="special_login_button">
                                    <div class="media_icon t"></div>
                                    <span>{{ $t('register_with') }} Twitter</span>
                                </div>
                            </div>
                            <span class="forgot_pass_button">{{ $t('forgotten_password') }}?</span>
                        </div>
                        <div class="login_form" v-else>
                            <input type="text" required :placeholder="$t('name_or_email')" v-model="loginData.loginUserData.username">
                            <input type="password" required :placeholder="$t('password')" v-model="loginData.loginUserData.password">
                            <button class="action_button" type="submit">
                                <span>{{ $t('login') }}</span>
                            </button>
                            <div class="space_separator">
                                <span class="line"></span>
                                <span>{{ $t('or') }}</span>
                                <span class="line"></span>
                            </div>
                            <div class="social_media_logReg_buttons_wrapper">
                                <div class="special_login_button">
                                    <div class="media_icon g"></div>
                                    <span>{{ $t('login_with') }} Google</span>
                                </div>
                                <div class="special_login_button">
                                    <div class="media_icon t"></div>
                                    <span>{{ $t('login_with') }} Twitter</span>
                                </div>
                            </div>
                            <span class="forgot_pass_button">{{ $t('forgotten_password') }}?</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <panel-futer></panel-futer>
    </main>
    

</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useLoginData } from '@/store/loginData';
import panelFuter from '@/views/panelFuter.vue'

const loginData = useLoginData()

const isRegistrating = ref(false)

function registerUser() {
    loginData.registerUser()
}

function loginUser() {
    loginData.loginUser()
}

function submitForm() {
    if (isRegistrating.value) registerUser()
    else loginUser()
}
</script>

<style lang="scss" scoped>
    @import 'src/styles/scss/loginPage.scss';
</style>